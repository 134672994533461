import classicPreset from '../assets/blocks-post-page/classic-preset.png';
import overlayPreset from '../assets/blocks-post-page/overlay-preset.png';
import stackedPreset from '../assets/blocks-post-page/stacked-preset.png';
import { POST_PAGE_PRESETS } from '../components/Post Page/post-page-constants';

export const getPostPageWidgetDesktopPresets = (rtl: boolean) => ({
  presets: [
    {
      id: rtl
        ? POST_PAGE_PRESETS.DESKTOP_CLASSIC_RIGHT
        : POST_PAGE_PRESETS.DESKTOP_CLASSIC_LEFT,
      src: classicPreset,
    },
    {
      id: rtl
        ? POST_PAGE_PRESETS.DESKTOP_OVERLAY_RIGHT
        : POST_PAGE_PRESETS.DESKTOP_OVERLAY_LEFT,
      src: overlayPreset,
    },
    {
      id: rtl
        ? POST_PAGE_PRESETS.DESKTOP_STACKED_RIGHT
        : POST_PAGE_PRESETS.DESKTOP_STACKED_LEFT,
      src: stackedPreset,
    },
  ],
});

export const getPostPageWidgetMobilePresets = (rtl: boolean) => ({
  presets: [
    {
      id: rtl
        ? POST_PAGE_PRESETS.MOBILE_CLASSIC_RIGHT
        : POST_PAGE_PRESETS.MOBILE_CLASSIC_LEFT,
      src: classicPreset,
    },
    {
      id: rtl
        ? POST_PAGE_PRESETS.MOBILE_OVERLAY_RIGHT
        : POST_PAGE_PRESETS.MOBILE_OVERLAY_LEFT,
      src: overlayPreset,
    },
    {
      id: rtl
        ? POST_PAGE_PRESETS.MOBILE_STACKED_RIGHT
        : POST_PAGE_PRESETS.MOBILE_STACKED_LEFT,
      src: stackedPreset,
    },
  ],
});
