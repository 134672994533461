import type {
  WidgetManifest,
  GfppDesktopConfig,
} from '@wix/platform-editor-sdk';
import { postPageWidgetDesign } from './blocks-post-page-manifest-widget-design';
import {
  getPostPageWidgetDesktopPresets,
  getPostPageWidgetMobilePresets,
} from './blocks-post-page-widget-presets';

interface BuildBlocksPostPageManifestParams {
  dataFromBlocks: WidgetManifest;
  rtl: boolean;
}

export const buildBlocksPostPageManifest = ({
  dataFromBlocks,
  rtl,
}: BuildBlocksPostPageManifestParams): WidgetManifest => ({
  ...dataFromBlocks,
  default: {
    ...dataFromBlocks.default,
    behavior: {
      removable: false,
      duplicatable: false,
      toggleShowOnAllPagesEnabled: false,
      essential: {
        enabled: false,
      },
    },
    gfpp: {
      ...dataFromBlocks.default.gfpp,
      desktop: {
        ...(dataFromBlocks.default.gfpp?.desktop ?? {}),
        iconButtons: (dataFromBlocks.default.gfpp?.desktop as GfppDesktopConfig)
          ?.iconButtons,
        widgetDesign: postPageWidgetDesign,
        widgetPresets: getPostPageWidgetDesktopPresets(rtl),
      },
      mobile: {
        ...(dataFromBlocks.default.gfpp?.mobile ?? {}),
        // @ts-expect-error
        widgetPresets: getPostPageWidgetMobilePresets(rtl),
      },
    },
  },
});
